import { Saga } from 'redux-saga';
import { fork } from 'redux-saga/effects';
import { listenToRouteHooks } from '../router/sagas/router';
import { listenToLoginRequest } from './authentication';
import { boot } from './boot';
import { listenToDismissNotificationById } from './notification';

export default function* root(childSagas?: Saga[]) {
  if (childSagas) {
    for (const key in childSagas) {
      if (childSagas[key]) {
        yield fork(childSagas[key]);
      }
    }
  }

  yield fork(listenToLoginRequest);
  yield fork(boot);
  yield fork(listenToRouteHooks);
  yield fork(listenToDismissNotificationById);
}
