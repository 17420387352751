import { ReduxRouter } from 'redux-router';
import { IamAuthLoginState } from './modules/iam-login-flow/redux/iam-login-flow-reducer';
import { AuthState } from './redux/auth/auth-reducer';
import { ModuleState } from './redux/module/module-reducer';
import { NotificationState } from './redux/notification/notification-reducer';
import { ProgressBarState } from './redux/progress-bar/progress-bar-reducer';
import { UiState } from './redux/ui/ui-reducer';

export interface AppState {
  router: ReduxRouter;
  ui: UiState;
  auth: AuthState;
  iamAuthLoginFlow: IamAuthLoginState;
  notifications: NotificationState;
  modules: ModuleState;
  progressBar: ProgressBarState;
}

// Redux actions and Dispatches

export interface StandardPayloadAction<Payload> extends StandardAction {
  payload: Payload;
}

export interface StandardAction {
  type: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  meta?: any;
}

// tslint:disable-next-line:no-shadowed-variable
export interface Dispatch<Action> {
  (action: Action): Action;
}

export interface Resource {
  loading: boolean;
  loaded: boolean;
}

export interface EntityResource<Entity> extends Resource {
  entity: Entity;
}

type ModelResourceResponsePayload = any;

export interface ResourceResponseMeta {
  total?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  queryParams: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pathParams: any;
}

// Error types
export type APIErrorStatus = 400 | 403 | 404 | 405 | 409 | 422;

export type ServerErrorStatus = APIErrorStatus | 500 | 501 | 503;

export interface ResourceError {
  code: string;
  detail: string;
  id: string;
  status: APIErrorStatus;
  title: string;
  source?: {
    field: string;
    message: string;
  };
}

export interface ServerErrorResponse {
  apiErrors?: ResourceError[];
  message?: string;
  status: ServerErrorStatus;
}

export interface ResourceResponse<Data> {
  data: Data;
  meta: ResourceResponseMeta;
  error?: ServerErrorResponse;
}

export type APIResourceResponse = ResourceResponse<ModelResourceResponsePayload>;

export interface AuthenticationErrorPayload {
  error: string;
  error_description: string;
}
export interface AuthenticationSuccessPayload {
  access_token: string;
  expires_in: number;
  token_type: string;
}

export type NullableString = string | null;

export type NullableBoolean = boolean | null;

export type AuthenticationErrorResponse = AuthenticationErrorPayload;

export enum AuthenticationMessageStatus {
  PRISTINE,
  LOGGED_OUT,
  WRONG_CREDENTIALS,
  LOGGED_OUT_FOR_INACTIVITY,
  NETWORK_INVALID,
  GENERIC_ERROR,
}

export interface ResourceState<Entity> extends ResourceResponseMeta {
  list: Entity[];
  total?: number;
  queryParams: {};
  pathParams: {};
}

export interface Navigate {
  navigate?: boolean;
}

export interface Dictionary<S> {
  [key: string]: S;
}

export enum NotificationReason {
  CREATE_RESOURCE,
  UPDATE_RESOURCE,
  DELETE_RESOURCE,
  CLOSE_RESOURCE,
  UPLOAD_DATA,
}

export interface RouterHookParams {
  queryParams: Dictionary<string | number>;
  pathParams: Dictionary<string>;
  routeName?: string;
}

export type Action<T> = StandardAction | StandardPayloadAction<T>;

export interface ListElementUiState {
  readonly selected?: boolean;
  readonly expanded?: boolean;
  readonly checked?: boolean;
}

export type HttpMethod = 'get' | 'patch' | 'post' | 'delete' | 'put';

export interface EndpointConfig {
  path: string;
  httpMethod: HttpMethod;
  requiredAction: string;
}

export interface ResourceConfig {
  resourceName: string;
  endpoints: Dictionary<EndpointConfig>;
}

export interface DocumentToUpload {
  file: File;
  filename: string;
  type: string;
}

export type ReplacementFilterSaga = (params: Dictionary<string>) => void;

export interface ModalProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onConfirm: (...args: any[]) => void;
  hideModal: () => void;
  afterClose?: () => void;
}

export interface ResourceFormActions {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmit: (...args: any[]) => void;
}

export interface ResourceFormProps extends ResourceFormActions {
  errors?: ServerErrorResponse;
}
