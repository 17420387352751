import { StandardPayloadAction } from '../../../types';
import { IamLoginFlowSteps, iamLoginFlowSteps } from '../models/iam-login-flow';
import { AUTH_IAM_SET_CALLBACK_STEP_ACTION } from './iam-login-flow-actions';
export interface IamAuthLoginState {
  callbackStep: IamLoginFlowSteps;
}

// The initial application state
const DEFAULT_STATE = {
  callbackStep: iamLoginFlowSteps.INITIAL,
};

export const iamAuthLoginFlow = (
  state: IamAuthLoginState = DEFAULT_STATE,
  action: StandardPayloadAction<IamLoginFlowSteps>,
): IamAuthLoginState => {
  switch (action.type) {
    case AUTH_IAM_SET_CALLBACK_STEP_ACTION:
      const authSetAction = action as StandardPayloadAction<IamLoginFlowSteps>;
      const setAuthActionPayload = authSetAction.payload;
      return {
        ...state,
        callbackStep: setAuthActionPayload,
      };
    default:
      return state;
  }
};
