import { take } from 'redux-saga/effects';
import { BOOT_INITIAL_LOAD_ACTION } from './../redux/boot/boot-actions';

export function* boot() {
  while (true) {
    // And we're listening for `boot/INITIAL_LOAD` actions and perform some tasks:
    // - load user policies
    yield take(BOOT_INITIAL_LOAD_ACTION);
  }
}
