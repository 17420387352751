export const en = {
  'auth.loggedOutForInactivity': 'You have been logged out due to inactivity, log back in to resume',
  'auth.successfullyLoggedOut': 'Successfully logged out',
  'auth.invalidNetwork': 'Please connect to your company VPN before attempting to log in.',
  'auth.wrongCredentials': 'Wrong username or password',
  'button.iam': 'Log in with IAM',
  'button.login': 'Log in',
  'label.email': 'Email',
  'label.password': 'Password',
  'pageSectionTitle.loading': 'Loading …',
  'pageSectionTitle.login': 'WebUI Login',
  'iam.callbackStep.AUTHENTICATING': 'Authenticating',
  'iam.callbackStep.DONE': 'Done',
  'iam.callbackStep.GET_USER_INFO': 'Getting user information',
  'iam.callbackStep.INITIAL': 'Initial',
};
