import { FunctionComponent } from 'react';
import { Redirect, Route, Router as ReactRouter, Switch } from 'react-router-dom';
import AuthenticationContainer from '../../../containers/authentication-container';
import { RouterHookParams } from '../../../types';
import history from '../../lib/history';
import { defaultHomeRoute, RoutesConfig } from '../../lib/routes-config';
import ProtectedRoute from '../protected-route';

interface RouterProps {
  loggedIn: boolean;
  routes: RoutesConfig;
  onEnterRoute: (params: RouterHookParams) => void;
}

const Router: FunctionComponent<RouterProps> = props => {
  const { routes, loggedIn, onEnterRoute } = props;

  return (
    <ReactRouter history={history}>
      <Switch>
        <Route exact path="/login" component={AuthenticationContainer} />
        {Object.keys(routes).map(routeName => {
          const path = routes[routeName].path;
          // Make 100% all paths start with a slash
          const pathWithSlash = path.startsWith('/') ? path : `/${path}`;
          const skipAuthentication = Boolean(routes[routeName].skipAuthentication);
          const currentComponent = routes[routeName].component;
          return (
            <ProtectedRoute
              key={`route-${routeName}`}
              loggedIn={loggedIn}
              onEnterRoute={onEnterRoute}
              routeName={routeName}
              path={pathWithSlash}
              skipAuthentication={skipAuthentication}
              currentComponent={currentComponent}
              exact={true} // Needed hardcoded here to match routes with pathParams
            />
          );
        })}
        <Redirect from="/" to={defaultHomeRoute} />
      </Switch>
    </ReactRouter>
  );
};

export default Router;
