import { RouterHookParams, StandardPayloadAction } from '../../types';

export const ON_ENTER_ROUTE_ACTION = 'router/ON_ENTER_ROUTE_ACTION';

export interface OnEnterRouteAction extends StandardPayloadAction<RouterHookParams> {
  readonly type: 'router/ON_ENTER_ROUTE_ACTION';
}

export function onEnterRouteAction(payload: RouterHookParams): OnEnterRouteAction {
  return {
    type: ON_ENTER_ROUTE_ACTION,
    payload,
  };
}
