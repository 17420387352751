import { Notification } from 'sb-ui-components';
import {
  NOTIFICATION_CREATE_ACTION,
  NOTIFICATION_DISMISSED_ACTION,
  NotificationAction,
  NotificationActionParams,
} from './notification-actions';

export type NotificationState = Notification[];

const notificationInitialState: NotificationState = [];

export const notifications = (
  state: NotificationState = notificationInitialState,
  action: NotificationAction<NotificationActionParams>,
): NotificationState => {
  // remove this early return if action without payload is consumed
  if (!action.payload) {
    return state;
  }
  switch (action.type) {
    case NOTIFICATION_CREATE_ACTION: {
      return state.concat([action.payload as Notification]);
    }
    case NOTIFICATION_DISMISSED_ACTION: {
      return state.filter(notitification => notitification.id !== action.payload);
    }
    default:
      return state;
  }
};
