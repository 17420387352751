import moment from 'moment';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { ApiRequestParams } from '../../../lib/api';
import authentication, { LOGIN_TYPE, TOKEN_VALID_UNTIL } from '../../../models/authentication';
import { goToLocationOfLastSession } from '../../../sagas/authentication';
import { resourceRequest } from '../../../sagas/resource-requests';
import { ResourceResponse } from '../../../types';
import { AUTH_IAM_LOGIN_REQUEST_ACTION } from '../../iam-user/redux/iam-user-actions';
import iamLoginFlowConfig, {
  IAM_LOGIN_FLOW_ENDPOINT_LOGIN,
  IAM_LOGIN_FLOW_ENDPOINT_OAUTH_INFO,
  IAM_LOGIN_FLOW_ENDPOINT_REQUEST_TOKEN,
  iamLoginFlowSteps,
  IamRequestLoginUrlSuccessPayload,
  IamToken,
  IamTokenInfo,
} from '../models/iam-login-flow';
import { setIamLoginFlowStep } from '../redux/iam-login-flow-actions';

export function* listenToIamLoginFlow() {
  yield takeEvery(AUTH_IAM_LOGIN_REQUEST_ACTION, handleInitiateIamLogin);
}

export function* handleInitiateIamLogin() {
  const params: ApiRequestParams = { pathParams: {}, queryParams: {} };
  const initiateIamLoginResponse: ResourceResponse<IamRequestLoginUrlSuccessPayload> = yield call(
    resourceRequest,
    iamLoginFlowConfig.endpoints[IAM_LOGIN_FLOW_ENDPOINT_LOGIN],
    params,
  );
  const {
    data: { oauth_authorization_url },
  } = initiateIamLoginResponse;
  if (oauth_authorization_url) {
    // give user 10 minutes for IAM login to be completed
    // otherwise login form may popup when coming back from
    // IAM login form due to loggedIn() check in auth-reducer
    // during initialization and before window reload.
    localStorage.setItem(
      TOKEN_VALID_UNTIL,
      moment()
        .add(10, 'minutes') // 10 minutes
        .unix()
        .toString(),
    );
    yield call(gotToOauthUrl, oauth_authorization_url);
  }
}

export function* gotToOauthUrl(url: string) {
  yield window.location.assign(url);
}

export function* iamCallbackPageEnter(params: ApiRequestParams): SagaIterator {
  yield put(setIamLoginFlowStep(iamLoginFlowSteps.AUTHENTICATING));
  const tokenResponse: ResourceResponse<IamToken> = yield call(loadAccessToken, params);
  const token: IamToken = tokenResponse.data;
  if (token) {
    yield call(storeAccessTokenData, token);
    yield put(setIamLoginFlowStep(iamLoginFlowSteps.DONE));
    yield call(goToLocationOfLastSession, authentication.getLastLocation());
  }
}

export function* loadAccessToken(params: ApiRequestParams) {
  const tokenResponse: ResourceResponse<IamToken> = yield call(
    resourceRequest,
    iamLoginFlowConfig.endpoints[IAM_LOGIN_FLOW_ENDPOINT_REQUEST_TOKEN],
    {
      ...params,
    },
  );
  return tokenResponse;
}

export function* storeAccessTokenData(token: IamToken) {
  const { expires_in } = token;
  yield localStorage.setItem(
    TOKEN_VALID_UNTIL,
    moment()
      .add(expires_in, 'seconds')
      .unix()
      .toString(),
  );
  localStorage.setItem(LOGIN_TYPE, 'IAM');
}

export function* getOauthInfo() {
  const params: ApiRequestParams = {
    pathParams: {},
    queryParams: {},
  };
  const iamInfoResponse: ResourceResponse<IamTokenInfo> = yield call(
    resourceRequest,
    iamLoginFlowConfig.endpoints[IAM_LOGIN_FLOW_ENDPOINT_OAUTH_INFO],
    params,
  );
  return iamInfoResponse;
}
