import { ModuleConfig } from './../../models/module';
import IamLoginFlowContainer from './containers/iam-login-flow-callback-container';
import { IAM_LOGIN_FLOW_RESOURCE_NAME } from './models/iam-login-flow';
import { iamAuthLoginFlow } from './redux/iam-login-flow-reducer';
import { iamCallbackPageEnter, listenToIamLoginFlow } from './sagas/iam-login-flow';
export const config: ModuleConfig = {
  moduleName: IAM_LOGIN_FLOW_RESOURCE_NAME,
  redux: {
    reducer: { iamAuthLoginFlow },
  },
  listenerSagas: [listenToIamLoginFlow],
  routes: {
    'iamLoginFlow.oautCallback': {
      path: '/oauth2/callback',
      component: IamLoginFlowContainer,
      saga: iamCallbackPageEnter,
      skipAuthentication: true,
    },
  },
};
