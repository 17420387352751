import { uniqueId } from 'lodash';
import { call, delay, put, race, spawn, take, takeEvery } from 'redux-saga/effects';
import { Notification, notificationTypes } from 'sb-ui-components';
import { getErrorMessage } from '../lib/api';
import {
  createNotification as createNotificationAction,
  dismissedNotification,
  dismissNotification as dismissNotificationAction,
  DismissNotificationAction,
  NOTIFICATION_DISMISS_ACTION,
  NOTIFICATION_DISMISSED_ACTION,
} from '../redux/notification/notification-actions';
import { NotificationReason, ResourceResponse } from '../types';

export function* listenToDismissNotificationById() {
  yield takeEvery(NOTIFICATION_DISMISS_ACTION, putDismissedNotification);
}

export function* putDismissedNotification(action: DismissNotificationAction) {
  yield put(dismissedNotification(action.payload!));
}
/**
 * Creates a new notification.
 * Use this method if you want to have full control of the message being displayed.
 */
export function* createNotification(notification: Notification) {
  yield put(createNotificationAction(notification));

  // let's wait for a dismiss action or dismiss automatically after x seconds
  yield race({
    dismissAction: take(NOTIFICATION_DISMISSED_ACTION),
    timeout: delay(4 * 1000),
  });

  yield put(dismissNotificationAction(notification.id));
}

export interface CreateNotificationFromApiResponseParams {
  apiResponse: ResourceResponse<{}>;
  notificationReason: NotificationReason;
  entityType?: string;
  entityId?: string;
  topic?: string;
}

export function* createNotificationFromApiResponse(params: CreateNotificationFromApiResponseParams) {
  const { apiResponse, notificationReason, topic, entityType, entityId } = params;
  const apiResponseSuccessful = !apiResponse.error;
  const notification: Notification = {
    id: createUniqueNotificationId(),
    topic: topic || buildNotificationTopic({ notificationReason, entityId, entityType, apiResponseSuccessful }),
    message: apiResponse.error && getErrorMessage(apiResponse.error),
    type: apiResponseSuccessful ? notificationTypes.SUCCESS : notificationTypes.ERROR,
  };
  yield spawn(createNotification, notification);
}

export function createUniqueNotificationId() {
  return uniqueId('notification_');
}

const formatEntityId = (entityId: string | undefined) => (entityId && ` ${entityId}`) || '';

export const buildNotificationTopic = (params: {
  notificationReason: NotificationReason;
  entityId?: string;
  entityType?: string;
  apiResponseSuccessful: boolean;
}): string => {
  const { entityId, entityType, notificationReason, apiResponseSuccessful } = params;
  switch (notificationReason) {
    case NotificationReason.CREATE_RESOURCE:
      return apiResponseSuccessful
        ? `Successfully created ${entityType || 'entity'}${formatEntityId(entityId)}`
        : `Error creating ${entityType || 'entity'}${formatEntityId(entityId)}`;

    case NotificationReason.UPDATE_RESOURCE:
      return apiResponseSuccessful
        ? `Successfully updated ${entityType || 'entity'}${formatEntityId(entityId)}`
        : `Error updating ${entityType || 'entity'}${formatEntityId(entityId)}`;

    case NotificationReason.DELETE_RESOURCE:
      return apiResponseSuccessful
        ? `Successfully deleted ${entityType || 'entity'}${formatEntityId(entityId)}`
        : `Error deleting ${entityType || 'entity'}${formatEntityId(entityId)}`;

    default:
      return apiResponseSuccessful ? 'Successfully requested entity' : 'Error requesting entity';
  }
};

export function* createCreateResourceNotification(
  response: ResourceResponse<{}>,
  entityType?: string,
  entityId?: string,
) {
  yield call(createNotificationFromApiResponse, {
    apiResponse: response,
    notificationReason: NotificationReason.CREATE_RESOURCE,
    entityType,
    entityId,
  });
}

export function* createUpdateResourceNotification(
  response: ResourceResponse<{}>,
  entityType: string,
  entityId?: string,
) {
  yield call(createNotificationFromApiResponse, {
    apiResponse: response,
    notificationReason: NotificationReason.UPDATE_RESOURCE,
    entityType,
    entityId,
  });
}

export function* createDeleteResourceNotification(
  response: ResourceResponse<{}>,
  entityType: string,
  entityId?: string,
) {
  yield call(createNotificationFromApiResponse, {
    apiResponse: response,
    notificationReason: NotificationReason.DELETE_RESOURCE,
    entityType,
    entityId,
  });
}
