import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import Authentication from '../components/authentication-page';
import { AuthenticationFormValues } from '../models/authentication';
import { iamLoginRequest } from '../modules/iam-user/redux/iam-user-actions';
import { loginRequest } from '../redux/auth/auth-actions';
import { Action, AppState } from '../types';

export const mapDispatchToProps = (dispatch: Dispatch<Action<{}>>) => {
  return {
    login: (formValues: AuthenticationFormValues) => {
      dispatch(loginRequest(formValues));
    },
    iamLogin: () => {
      dispatch(iamLoginRequest());
    },
  };
};

export const mapStateToProps = (state: AppState) => {
  return {
    statusMessage: state.auth.statusMessage,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Authentication);
