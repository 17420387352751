import { StandardAction } from '../../../types';

export const AUTH_IAM_LOGIN_REQUEST_ACTION = 'auth/IAM_LOGIN_REQUEST';

export interface IamLoginRequestAction extends StandardAction {
  readonly type: typeof AUTH_IAM_LOGIN_REQUEST_ACTION;
}

export function iamLoginRequest(): IamLoginRequestAction {
  return {
    type: AUTH_IAM_LOGIN_REQUEST_ACTION,
  };
}
