import authentication from '../../models/authentication';
import { AuthenticationMessageStatus } from '../../types';
import {
  AUTH_REQUEST_ERROR_ACTION,
  AUTH_SENDING_REQUEST_ACTION,
  AUTH_SET_AUTH_ACTION,
  AuthAction,
  AuthActionParams,
  AuthRequestErrorActionPayload,
  SendRequestActionPayload,
  SetAuthActionPayload,
} from './auth-actions';

export interface AuthState {
  loggedIn: boolean;
  currentlySending: boolean;
  statusMessage: AuthenticationMessageStatus;
}

// The initial application state
const DEFAULT_STATE = {
  currentlySending: false,
  loggedIn: authentication.loggedIn(),
  statusMessage: AuthenticationMessageStatus.PRISTINE,
};

const getAuthStatusMessage = (payload: SetAuthActionPayload) =>
  payload.newAuthState
    ? AuthenticationMessageStatus.PRISTINE
    : payload.isReasonInactivity
    ? AuthenticationMessageStatus.LOGGED_OUT_FOR_INACTIVITY
    : AuthenticationMessageStatus.LOGGED_OUT;

const getAuthErrorMessage = (httpStatusCode: number) => {
  switch (httpStatusCode) {
    case 401:
      return AuthenticationMessageStatus.WRONG_CREDENTIALS;
    case 403:
      return AuthenticationMessageStatus.NETWORK_INVALID;
    default:
      return AuthenticationMessageStatus.GENERIC_ERROR;
  }
};

export const auth = (state: AuthState = DEFAULT_STATE, action: AuthAction<AuthActionParams>): AuthState => {
  switch (action.type) {
    case AUTH_SET_AUTH_ACTION:
      if (!action.payload) {
        return state;
      }
      const setAuthActionPayload = action.payload as SetAuthActionPayload;
      return {
        ...state,
        loggedIn: setAuthActionPayload.newAuthState,
        statusMessage: getAuthStatusMessage(setAuthActionPayload),
      };
    case AUTH_SENDING_REQUEST_ACTION:
      const sendRequestActionPayload = action.payload as SendRequestActionPayload;
      return {
        ...state,
        currentlySending: sendRequestActionPayload.sending,
      };
    case AUTH_REQUEST_ERROR_ACTION:
      const loginErrorPayload = action.payload as AuthRequestErrorActionPayload;
      return {
        ...state,
        currentlySending: false,
        statusMessage: getAuthErrorMessage(loginErrorPayload.httpResponseCode),
      };
    default:
      return state;
  }
};
