import { StandardAction } from '../../types';

export const BOOT_INITIAL_LOAD_ACTION = 'boot/INITIAL_LOAD';

export interface BootAction extends StandardAction {
  readonly type: typeof BOOT_INITIAL_LOAD_ACTION;
}

export function boot() {
  return {
    type: BOOT_INITIAL_LOAD_ACTION,
  };
}
