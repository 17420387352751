import { StandardAction, StandardPayloadAction } from '../../../types';
import { IamLoginFlowSteps } from '../models/iam-login-flow';

export const AUTH_IAM_FETCH_TOKEN_REQUEST_ACTION = 'auth/IAM_FETCH_TOKEN_REQUEST';
export const AUTH_IAM_SET_CALLBACK_STEP_ACTION = 'auth/IAM_SET_CALLBACK_STEP';
export const AUTH_IAM_SET_AUTH_STATE_ACTION = 'auth/IAM_SET_AUTH_STATE';
export const AUTH_IAM_SET_ORGANISATION_PARTNERS_ACTION = 'auth/IAM_SET_ORGANISATION_PARTNERS';

export interface IamFetchTokenRequestAction extends StandardAction {
  readonly type: typeof AUTH_IAM_FETCH_TOKEN_REQUEST_ACTION;
}

export function iamFetchTokenRequest(): IamFetchTokenRequestAction {
  return {
    type: AUTH_IAM_FETCH_TOKEN_REQUEST_ACTION,
  };
}

export interface SetIamCallbackStepAction extends StandardPayloadAction<IamLoginFlowSteps> {
  readonly type: typeof AUTH_IAM_SET_CALLBACK_STEP_ACTION;
}

export function setIamLoginFlowStep(step: IamLoginFlowSteps): SetIamCallbackStepAction {
  return {
    type: AUTH_IAM_SET_CALLBACK_STEP_ACTION,
    payload: step,
  };
}

export interface SetIamAuthStateAction extends StandardPayloadAction<boolean> {
  readonly type: typeof AUTH_IAM_SET_AUTH_STATE_ACTION;
}
export function setIamAuthState(isLoggedIn: boolean): SetIamAuthStateAction {
  return {
    type: AUTH_IAM_SET_AUTH_STATE_ACTION,
    payload: isLoggedIn,
  };
}
