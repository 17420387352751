import { ComponentType } from 'react';
import { RouterHookParams } from '../../types';

export interface RouteConfig {
  component: ComponentType<any>;
  path: string;
  saga?: (params: RouterHookParams) => void;
  skipAuthentication?: boolean;
}

export interface RoutesConfig {
  [key: string]: RouteConfig;
}

export const defaultHomeRoute = '/login';
