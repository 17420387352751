import { StandardAction, StandardPayloadAction } from '../../types';

export const AUTH_SET_AUTH_ACTION = 'auth/SET_AUTH';
export const AUTH_SENDING_REQUEST_ACTION = 'auth/SENDING_REQUEST';
export const AUTH_LOGIN_REQUEST_ACTION = 'auth/LOGIN_REQUEST';
export const AUTH_LOGOUT_ACTION = 'auth/LOGOUT';
export const AUTH_REQUEST_ERROR_ACTION = 'auth/REQUEST_ERROR';

export type AuthActionParams =
  | SetAuthActionPayload
  | SendRequestActionPayload
  | LoginRequestActionParams
  | LogoutActionPayload
  | {};
export type AuthAction<T> = StandardPayloadAction<T>;

export interface SetAuthActionPayload {
  readonly newAuthState: boolean;
  readonly isReasonInactivity?: boolean;
}

export interface SetAuthAction extends AuthAction<SetAuthActionPayload> {
  readonly type: typeof AUTH_SET_AUTH_ACTION;
}

/**
 * Sets the authentication state of the application
 * @param  {boolean} newAuthState True means a user is logged in, false means no user is logged in
 */
export function setAuthState(newAuthState: boolean, isReasonInactivity?: boolean): SetAuthAction {
  return {
    type: AUTH_SET_AUTH_ACTION,
    payload: {
      newAuthState,
      isReasonInactivity,
    },
  };
}

export interface SendRequestActionPayload {
  readonly sending: boolean;
}

export interface SendRequestAction extends AuthAction<SendRequestActionPayload> {
  readonly type: typeof AUTH_SENDING_REQUEST_ACTION;
}

/**
 * Sets the `currentlySending` state, which displays a loading indicator during requests
 * @param  {boolean} sending True means we're sending a request, false means we're not
 */
export function sendingAuthRequest(sending: boolean): SendRequestAction {
  return {
    type: AUTH_SENDING_REQUEST_ACTION,
    payload: { sending },
  };
}

export interface LoginRequestActionParams {
  readonly username: string;
  readonly password: string;
}

export interface LoginRequestAction extends AuthAction<LoginRequestActionParams> {
  readonly type: typeof AUTH_LOGIN_REQUEST_ACTION;
}

/**
 * Tells the app we want to log in a user
 */
export function loginRequest({ username, password }: LoginRequestActionParams): LoginRequestAction {
  return {
    type: AUTH_LOGIN_REQUEST_ACTION,
    payload: {
      username,
      password,
    },
  };
}

interface LogoutActionPayload {
  readonly inactive?: boolean;
}

export interface LogoutAction extends AuthAction<LogoutActionPayload> {
  readonly type: typeof AUTH_LOGOUT_ACTION;
}

/**
 * Tells the app we want to log out a user
 */
export function logout(inactive?: boolean): LogoutAction {
  return {
    type: AUTH_LOGOUT_ACTION,
    payload: { inactive },
  };
}

export interface AuthRequestErrorAction extends StandardAction {
  readonly type: typeof AUTH_REQUEST_ERROR_ACTION;
}

/**
 * Sets the `error` state to the error received
 * @param  {object} error The error we got when trying to make the request
 */

export interface AuthRequestErrorActionPayload {
  httpResponseCode: number;
}
export interface AuthRequestErrorAction extends StandardPayloadAction<AuthRequestErrorActionPayload> {
  readonly type: typeof AUTH_REQUEST_ERROR_ACTION;
}

export function authRequestError(payload: AuthRequestErrorActionPayload): AuthRequestErrorAction {
  return {
    type: AUTH_REQUEST_ERROR_ACTION,
    payload,
  };
}
