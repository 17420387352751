import { StandardAction, StandardPayloadAction } from '../../types';

export const UI_TOGGLE_ADVANCED_FILTERS_ACTION = 'ui/TOGGLE_ADVANCED_FILTERS';
export const UI_TOGGLE_LIST_ITEM_ACTION = 'ui/TOGGLE_LIST_ITEM';
export const UI_DOWNLOAD_RESOURCE_START_ACTION = 'ui/DOWNLOAD_RESOURCE_START';
export const UI_DOWNLOAD_RESOURCE_COMPLETED_ACTION = 'ui/DOWNLOAD_RESOURCE_COMPLETED';
export const UI_TOGGLE_SWITCH_ACTION = 'ui/TOGGLE_SWITCH_ACTION';
export const UI_RESET_SWITCH_ACTION = 'ui/RESET_SWITCH_ACTION';
export const UI_SET_FORM_FIELDS_ACTION = 'ui/UI_SET_FORM_FIELDS_ACTION';
export const UI_RESET_FORM_FIELDS_ACTION = 'ui/UI_RESET_FORM_FIELDS_ACTION';

export type UiFormFieldsResourceName = string;
export type UIActionParams =
  | ListToggleItemPayload
  | DownloadingResourcePayload
  | UiToggleSwitchPayload
  | UiSetFormFieldsPayload;
export type UIAction<T> = StandardPayloadAction<T>;

export interface UiSetFormFieldsPayload {
  formFields: string[];
  resourceName: UiFormFieldsResourceName;
}

export interface UiSetFormFieldsAction extends UIAction<UiSetFormFieldsPayload> {
  readonly type: typeof UI_SET_FORM_FIELDS_ACTION;
}

export function uiSetFormFields(formFields: string[], resourceName: UiFormFieldsResourceName): UiSetFormFieldsAction {
  return {
    type: UI_SET_FORM_FIELDS_ACTION,
    payload: { formFields, resourceName },
  };
}

export interface UiResetFormFieldsAction extends StandardAction {
  readonly type: typeof UI_RESET_FORM_FIELDS_ACTION;
}

export function uiResetFormFields(): UiResetFormFieldsAction {
  return {
    type: UI_RESET_FORM_FIELDS_ACTION,
  };
}

export interface UiResetSwitchAction extends StandardAction {
  readonly type: typeof UI_RESET_SWITCH_ACTION;
}

export function uiResetSwitch(): UiResetSwitchAction {
  return {
    type: UI_RESET_SWITCH_ACTION,
  };
}

export interface UiToggleSwitchPayload {
  resourceName: string;
}

export interface UiToggleSwitchAction extends UIAction<UiToggleSwitchPayload> {
  readonly type: typeof UI_TOGGLE_SWITCH_ACTION;
}

export function uiToggleSwitch(resourceName: string): UiToggleSwitchAction {
  return {
    type: UI_TOGGLE_SWITCH_ACTION,
    payload: { resourceName },
  };
}

export interface ListToggleItemPayload {
  id: string;
  expandedResourceName?: string;
}

export interface ListItemToggleAction extends UIAction<ListToggleItemPayload> {
  readonly type: typeof UI_TOGGLE_LIST_ITEM_ACTION;
}

export function toggleListItem(id: string, expandedResourceName?: string): ListItemToggleAction {
  return {
    type: UI_TOGGLE_LIST_ITEM_ACTION,
    payload: { id, expandedResourceName },
  };
}

export interface DownloadingResourcePayload {
  id: string;
}

export interface DownloadResourceStartAction extends UIAction<DownloadingResourcePayload> {
  readonly type: typeof UI_DOWNLOAD_RESOURCE_START_ACTION;
}

export function downloadElementStart(id: string): DownloadResourceStartAction {
  return {
    type: UI_DOWNLOAD_RESOURCE_START_ACTION,
    payload: { id },
  };
}
export interface DownloadResourceCompletedAction extends UIAction<DownloadingResourcePayload> {
  readonly type: typeof UI_DOWNLOAD_RESOURCE_COMPLETED_ACTION;
}

export function downloadElementCompleted(id: string): DownloadResourceCompletedAction {
  return {
    type: UI_DOWNLOAD_RESOURCE_COMPLETED_ACTION,
    payload: { id },
  };
}
