import { Dictionary, ListElementUiState, NullableString } from '../../types';
import {
  DownloadingResourcePayload,
  ListToggleItemPayload,
  UI_DOWNLOAD_RESOURCE_COMPLETED_ACTION,
  UI_DOWNLOAD_RESOURCE_START_ACTION,
  UI_RESET_FORM_FIELDS_ACTION,
  UI_RESET_SWITCH_ACTION,
  UI_SET_FORM_FIELDS_ACTION,
  UI_TOGGLE_LIST_ITEM_ACTION,
  UIAction,
  UIActionParams,
  UiSetFormFieldsPayload,
} from './ui-actions';

export type ListItemUiList = Dictionary<ListElementUiState>;
export type UiElements = Dictionary<ListElementUiState>;
export type PropertyToToggle = 'expanded' | 'checked';

export function toggleSelectedListItems(listItems: ListItemUiList, id: string): ListItemUiList {
  const previousSelectedState = listItems[id] && listItems[id].selected;
  return {
    ...listItems,
    [id]: {
      selected: !previousSelectedState,
    },
  };
}

export interface NoteUiState {
  readonly noteInputExpanded: boolean;
  readonly expandedUpdateNoteId: NullableString;
}
export interface UiState {
  readonly listItemEntities: ListItemUiList;
  readonly downloadingElements: string[];
  readonly note: NoteUiState;
  readonly switchElements: UiElements;
  readonly uiFormFields: {};
}

export const DEFAULT_STATE: UiState = {
  listItemEntities: {},
  switchElements: {},
  downloadingElements: [],
  uiFormFields: {},
  note: {
    noteInputExpanded: false,
    expandedUpdateNoteId: null,
  },
};

export const ui = (state: UiState = DEFAULT_STATE, action: UIAction<UIActionParams>): UiState => {
  switch (action.type) {
    case UI_TOGGLE_LIST_ITEM_ACTION:
      const toggleListItemPayload = action.payload as ListToggleItemPayload;
      const listItemEntity = state.listItemEntities[toggleListItemPayload.id];
      return {
        ...state,
        listItemEntities: {
          [toggleListItemPayload.id]: { expanded: listItemEntity ? !listItemEntity.expanded : true },
        },
      };

    case UI_DOWNLOAD_RESOURCE_START_ACTION:
      const downloadingElementPayload = action.payload as DownloadingResourcePayload;
      return {
        ...state,
        downloadingElements: [...state.downloadingElements, downloadingElementPayload.id],
      };

    case UI_DOWNLOAD_RESOURCE_COMPLETED_ACTION:
      const downloadedElementPayload = action.payload as DownloadingResourcePayload;
      const downloadingElements = state.downloadingElements;

      return {
        ...state,
        downloadingElements: downloadingElements.filter(el => el !== downloadedElementPayload.id),
      };

    case UI_RESET_SWITCH_ACTION:
      return {
        ...state,
        switchElements: {},
      };

    case UI_SET_FORM_FIELDS_ACTION:
      const formFieldsPayload = action.payload as UiSetFormFieldsPayload;
      return {
        ...state,
        uiFormFields: {
          ...state.uiFormFields,
          [formFieldsPayload.resourceName]: formFieldsPayload.formFields,
        },
      };

    case UI_RESET_FORM_FIELDS_ACTION:
      return {
        ...state,
        uiFormFields: {},
      };

    default:
      return state;
  }
};
