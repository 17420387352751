import { FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import { injectIntl, IntlShape } from 'react-intl';
import { Button, TextInput } from 'sb-ui-components';
import { AuthenticationFormValues } from '../../models/authentication';
import { ResourceFormProps } from '../../types';
import './authentication-form.css';

const AuthenticationForm: FunctionComponent<ResourceFormProps & { intl: IntlShape }> = props => {
  const {
    intl: { formatMessage },
    onSubmit,
  } = props;
  const {
    handleSubmit,
    register,
    formState: { isSubmitting },
  } = useForm<AuthenticationFormValues>({ mode: 'onChange' });
  return (
    <form className="s-authentication-form" onSubmit={handleSubmit(onSubmit)}>
      <TextInput id="username" label={formatMessage({ id: 'label.email' })} register={register} />
      <TextInput id="password" type="password" label={formatMessage({ id: 'label.password' })} register={register} />
      <Button
        dataCy="cta__login"
        type="submit"
        size="big"
        className="s-authentication-form__button"
        disabled={isSubmitting}
      >
        {formatMessage({ id: 'button.login' })}
      </Button>
    </form>
  );
};
export default injectIntl(AuthenticationForm);
