import { ResourceConfig } from '../../../types';

export const IAM_USER_RESOURCE_NAME = 'iamUser';

export interface IamUser {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  organization_id: string;
  phone: string;
  created_at: string;
  blocked_at?: string;
}

const iamUserResourceConfig: ResourceConfig = {
  resourceName: IAM_USER_RESOURCE_NAME,
  endpoints: {
    show: {
      path: '/v1/iam/users/{id}',
      httpMethod: 'get',
      requiredAction: 'organizations_iam_users#list',
    },
  },
};

export default iamUserResourceConfig;
