import { applyMiddleware, compose, legacy_createStore as createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { AppState } from './../types';
import { reducer } from './combinedReducer';

export const sagaMiddleware = createSagaMiddleware();
// @ts-ignore - we ignore this access exceptionally
const composeEnhancer = window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] || compose;

export const store = createStore<AppState, any, any, any>(reducer, composeEnhancer(applyMiddleware(sagaMiddleware)));
