import axios, { AxiosResponse } from 'axios';
import {
  PROGRESS_FINISH_LOADING_ACTION,
  PROGRESS_START_LOADING_ACTION,
} from 'src/redux/progress-bar/progress-bar-actions';
import { store } from 'src/redux/store';
import { environmentApiUrl } from '../lib/environment';
import {
  APIResourceResponse,
  Dictionary,
  EndpointConfig,
  ResourceError,
  ResourceResponse,
  ResourceResponseMeta,
  ServerErrorResponse,
  ServerErrorStatus,
} from '../types';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const URI = require('urijs');
// eslint-disable-next-line @typescript-eslint/no-var-requires
const URITemplate = require('urijs/src/URITemplate');
export const RECOVERABLE_STATUS_CODES = [401, 400, 409, 412];

export interface APIDomainConfig {
  api: string;
}

export const API_CONFIG: APIDomainConfig = {
  api: environmentApiUrl(),
};

export const defaultHeaders = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

type DefaultHeaders = typeof defaultHeaders;

interface AuthorizationHeader {
  Authorization?: string;
}

export interface Headers extends DefaultHeaders, AuthorizationHeader {}

export interface ApiRequestParams {
  queryParams: Dictionary<string | number | string[]>;
  pathParams: Dictionary<string>;
  headerParams?: Dictionary<string>;
}

/*
Exception example:
{
"id":"32b957fb44173ef1b52a731637468ab6ex",
"status":404,
"code":"model_not_found",
"title":"Model Not Found",
"detail":"Couldn't find 'Solaris::Identification' for id '16bb1e948be0f2319583d990dd8a1c57ident'."
}
*/
export interface Exception {
  id?: string;
  status?: number;
  code?: string;
  title: string;
  detail: string;
}

export interface ApiRequest extends ApiRequestParams {
  url: string;
  payload?: {};
  method: 'get' | 'post' | 'patch' | 'delete' | 'put';
}

export function getRequiredPathParamsFromConfig(path: string): string[] {
  const params = path.match(/{.*?}/g);
  if (!params) {
    return [];
  }
  return params.map((value: string) => value.replace(/{|}/g, ''));
}

export function buildRequestConfig(
  enpointConfig: EndpointConfig,
  params: ApiRequestParams = { queryParams: {}, pathParams: {}, headerParams: {} },
  payload?: {},
): ApiRequest {
  const config = API_CONFIG;
  const headerParams = params.headerParams || {};
  const pathParams = params.pathParams || {};
  const queryParams = params.queryParams || {};
  const uriPath = URITemplate(enpointConfig.path).expand(pathParams);
  const path = URI(uriPath)
    .query(queryParams)
    .toString();
  const domainURL = config.api;
  const url = domainURL + path;
  const apiRequest: ApiRequest = {
    url,
    payload,
    queryParams,
    pathParams,
    headerParams,
    ...{ method: enpointConfig.httpMethod },
  };

  return apiRequest;
}

export function buildHeaders(headerParams: {} | undefined) {
  const accessToken = localStorage.getItem('token');
  const headers: Headers = {
    ...defaultHeaders,
    ...headerParams,
  };
  if (accessToken) {
    headers.Authorization = `Bearer ${accessToken}`;
  }
  return headers;
}

/*
  bakes a ResourceResponse out of an AxiosResponse object
*/
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function processResponse(response: AxiosResponse, params: ApiRequestParams): ResourceResponse<any> {
  const meta: ResourceResponseMeta = {
    total: response.headers && response.headers.total ? +response.headers.total : 0,
    queryParams: params.queryParams || {},
    pathParams: params.pathParams || {},
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const resourceReponse: ResourceResponse<any> = {
    data: response.data,
    meta,
  };
  return resourceReponse;
}

export function request(params: ApiRequest): Promise<APIResourceResponse> {
  const { url, method, payload, headerParams } = params;
  const headers = buildHeaders(headerParams);
  const options = {
    url,
    headers,
    method,
    ...{ data: payload },
  };
  return genericRequest(params, options, { logErrors: true });
}

export function genericRequest(
  params: ApiRequest,
  options: {},
  loggingOptions: { logErrors: boolean },
): Promise<APIResourceResponse> {
  const { queryParams, pathParams } = params;
  return new Promise(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function(resolve: any, reject: any) {
      store.dispatch({ type: PROGRESS_START_LOADING_ACTION });

      axios
        .request(options)
        .then((response: AxiosResponse) => {
          resolve(processResponse(response, { queryParams, pathParams }));

          store.dispatch({ type: PROGRESS_FINISH_LOADING_ACTION });
        })
        .catch((errorResponse: { response: AxiosResponse }) => {
          // axios rejects the promise on all HTTP statuses not being between 200 and 300
          // we can define the valid statuses with validateStatus parameter
          // see https://github.com/mzabriskie/axios#user-content-request-config

          // TODO put an action for raven middleware
          // TODO DELETE
          const response = errorResponse.response;
          const resourceResponse: ResourceResponse<{}> = {
            data: {},
            meta: {
              queryParams,
              pathParams,
            },
            error: {
              apiErrors: (response.data && response.data.errors) || undefined,
              message: response.statusText,
              status: response.status as ServerErrorStatus,
            },
          };
          if (loggingOptions.logErrors && hasUnrecoverableError(resourceResponse)) {
            // We don not want to log errors like 400s because of an invalid form submission
            console.warn(`Exception: ${(response.data && response.data.errors) || response}.`);
          }
          reject(resourceResponse);

          store.dispatch({ type: PROGRESS_FINISH_LOADING_ACTION });
        });
    },
  );
}

export function hasUnrecoverableError(resourceResponse: ResourceResponse<{}>) {
  return resourceResponse.error && RECOVERABLE_STATUS_CODES.indexOf(resourceResponse.error.status) < 0;
}

export const apiErrorDetails = (apiErrors: ResourceError[]): string =>
  apiErrors
    .map(apiError => {
      return apiError.source
        ? `${apiError.source.field}: ${apiError.source.message}`
        : `${apiError.title} ${apiError.detail}`;
    })
    .join(', ');

export const hasApiErrors = (error: ServerErrorResponse) => !!(error.apiErrors && error.apiErrors.length > 0);

export const getErrorMessage = (error: ServerErrorResponse, message?: string): string => {
  const details = error && hasApiErrors(error) && apiErrorDetails(error.apiErrors!);
  return message || (error!.message ? `${error!.message}: ${details}` : details) || `${error.status} exception`;
};
