import { Notification } from 'sb-ui-components';
import { StandardPayloadAction } from '../../types';

export const NOTIFICATION_CREATE_ACTION = 'notification/CREATE';
export const NOTIFICATION_DISMISS_ACTION = 'notification/DISMISS';
export const NOTIFICATION_DISMISSED_ACTION = 'notification/DISMISSED';

export type NotificationActionParams = string | Notification;
export type NotificationAction<T> = StandardPayloadAction<T>;

export interface CreateNotificationAction extends NotificationAction<Partial<Notification>> {
  readonly type: typeof NOTIFICATION_CREATE_ACTION;
}

export function createNotification(notification: Notification): CreateNotificationAction {
  return {
    type: NOTIFICATION_CREATE_ACTION,
    payload: notification,
  };
}

export interface DismissNotificationAction extends NotificationAction<string> {
  readonly type: typeof NOTIFICATION_DISMISS_ACTION;
}

export function dismissNotification(id: string): DismissNotificationAction {
  return {
    type: NOTIFICATION_DISMISS_ACTION,
    payload: id,
  };
}

export interface DismissedNotificationAction extends NotificationAction<string> {
  readonly type: typeof NOTIFICATION_DISMISSED_ACTION;
}

export function dismissedNotification(id: string): DismissedNotificationAction {
  return {
    type: NOTIFICATION_DISMISSED_ACTION,
    payload: id,
  };
}
