import invariant from 'invariant';
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { uiResetFormFields, uiResetSwitch } from '../../redux/ui/ui-actions';
import { AppState } from '../../types';
import { RoutesConfig } from '../lib/routes-config';
import { ON_ENTER_ROUTE_ACTION, OnEnterRouteAction } from '../redux/router-actions';

export function* listenToRouteHooks() {
  yield takeEvery(ON_ENTER_ROUTE_ACTION, handlePageEnter);
}

export function* handlePageEnter(action: OnEnterRouteAction) {
  yield all([put(uiResetSwitch()), put(uiResetFormFields())]); // TODO do we need this?
  yield fork(runSaga, action);
}

export function* runSaga(action: OnEnterRouteAction) {
  const { payload } = action;
  const routes: RoutesConfig = yield select((state: AppState) => state.modules.routes);

  if (payload && payload.routeName) {
    invariant(routes[payload.routeName], `No configuration found for ${payload.routeName}.`);
    const saga = routes[payload.routeName].saga;
    if (saga) {
      try {
        yield call(saga, {
          queryParams: payload.queryParams,
          pathParams: payload.pathParams,
        });
      } catch (err) {
        console.warn(`Error calling saga: ${err.sagaStack}`);
      }
    }
  }
}
