import { ModuleConfig } from '../../models/module';
import { RoutesConfig } from '../../router/lib/routes-config';
import { Dictionary } from './../../types';
import { ModuleAction, ModuleActionParams, MODULES_SET_ROUTES_ACTION } from './module-actions';

export interface ModuleState {
  readonly routes: RoutesConfig;
  readonly modules: Dictionary<ModuleConfig>;
}

const DEFAULT_STATE: ModuleState = {
  routes: {},
  modules: {},
};

export const modules = (state: ModuleState = DEFAULT_STATE, action: ModuleAction<ModuleActionParams>): ModuleState => {
  if (!action.payload) {
    return state;
  }
  switch (action.type) {
    case MODULES_SET_ROUTES_ACTION:
      return {
        routes: action.payload.routes,
        modules: action.payload.modules,
      };
    default:
      return state;
  }
};
