import { useSelector } from 'react-redux';
import { ProgressBarLine } from 'sb-ui-components';
import { AppState } from 'src/types';

export const GlobalProgressBar = () => {
  const { isProgressLoading } = useSelector((state: AppState) => state.progressBar);
  const { currentlySending: authRequestLoading } = useSelector((state: AppState) => state.auth);

  if (isProgressLoading || authRequestLoading) return <ProgressBarLine />;

  return null;
};
