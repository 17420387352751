import { Enum } from 'typescript-string-enums';
import { ResourceConfig } from '../../../types';

export const IAM_LOGIN_FLOW_RESOURCE_NAME = 'iamLoginFlow';
export const IAM_LOGIN_FLOW_ENDPOINT_REQUEST_TOKEN = 'requestToken';
export const IAM_LOGIN_FLOW_ENDPOINT_LOGIN = 'login';
export const IAM_LOGIN_FLOW_ENDPOINT_OAUTH_INFO = 'info';
export const IAM_LOGIN_FLOW_ENDPOINT_GET_PARTNER = 'getCurrentPartner';

export const IAM_SELECTED_PARTNER_STORAGE_KEY = 'selectedPartner';

export const iamLoginFlowSteps = Enum('INITIAL', 'AUTHENTICATING', 'GET_USER_INFO', 'DONE');

export type IamLoginFlowSteps = Enum<typeof iamLoginFlowSteps>;

export interface IamToken {
  expires_in: number;
}

export interface IAMPartner {
  id: string;
  name: string;
}
export interface IamTokenInfo {
  client_id: string;
  user_id: string;
  partners: IAMPartner[];
}
export interface IamRequestLoginUrlSuccessPayload {
  oauth_authorization_url: string;
}

export interface IamLoadSelectedPartnerSuccessPayload {
  partner_id: string;
}

export interface IamCurrentPartner {
  current_partner: string;
}

const iamLoginFlowResourceConfig: ResourceConfig = {
  resourceName: IAM_LOGIN_FLOW_RESOURCE_NAME,
  endpoints: {
    [IAM_LOGIN_FLOW_ENDPOINT_REQUEST_TOKEN]: {
      path: '/auth/iam-request-token',
      httpMethod: 'post',
      requiredAction: '',
    },
    [IAM_LOGIN_FLOW_ENDPOINT_LOGIN]: {
      path: '/auth/iam-login',
      httpMethod: 'post',
      requiredAction: '',
    },
    [IAM_LOGIN_FLOW_ENDPOINT_OAUTH_INFO]: {
      path: '/auth/oauth2/info',
      httpMethod: 'get',
      requiredAction: '',
    },
    [IAM_LOGIN_FLOW_ENDPOINT_GET_PARTNER]: {
      path: '/auth/current-partner',
      httpMethod: 'get',
      requiredAction: '',
    },
  },
};

export default iamLoginFlowResourceConfig;
