import { createRoot } from 'react-dom/client';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { GlobalProgressBar } from './components/progress-bar';
import { boot } from './redux/boot/boot-actions';
import { setRoutes } from './redux/module/module-actions';
import { sagaMiddleware, store } from './redux/store';
import RouterContainer from './router/containers/router-container';
import sagaRoot from './sagas/index';
import { getRootSagas, getRoutes, modules } from './config';
import * as messages from './messages';
// Global CSS must be imported always before routes so it's loaded before components CSS
// (otherwise precedence issues will happen and the world will collapse)
import './css/global.css';

sagaMiddleware.run(sagaRoot, getRootSagas());

const allRoutes = getRoutes();

store.dispatch(
  setRoutes({
    modules: modules,
    routes: allRoutes,
  }),
);

store.dispatch(boot());

// locale prop can be changed dynamically in runtime.
const locale = 'en';

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <IntlProvider locale={locale} messages={messages[locale]}>
    <Provider store={store}>
      <RouterContainer routes={allRoutes} />
      <GlobalProgressBar />
    </Provider>
  </IntlProvider>,
);
