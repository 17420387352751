import { StandardAction } from '../../types';

export const PROGRESS_START_LOADING_ACTION = 'progress/START_LOADING';
export const PROGRESS_FINISH_LOADING_ACTION = 'progress/FINISH_LOADING';

export interface ProgressStartAction extends StandardAction {
  readonly type: typeof PROGRESS_START_LOADING_ACTION;
}

export interface ProgressFinishAction extends StandardAction {
  readonly type: typeof PROGRESS_FINISH_LOADING_ACTION;
}

export function startProgressAction(): ProgressStartAction {
  return {
    type: PROGRESS_START_LOADING_ACTION,
  };
}

export function finishProgressAction(): ProgressFinishAction {
  return {
    type: PROGRESS_FINISH_LOADING_ACTION,
  };
}
