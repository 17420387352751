import { connect } from 'react-redux';
import { AppState } from '../../../types';
import IamLoginFlowCallbackPage from '../components/iam-login-flow-callback-page/iam-login-flow-callback-page';

export const mapStateToProps = (state: AppState) => {
  return {
    currentIamCallbackStep: state.iamAuthLoginFlow.callbackStep,
    loggedIn: state.auth.loggedIn,
  };
};

export default connect(mapStateToProps, {})(IamLoginFlowCallbackPage);
