import { Saga } from 'redux-saga';
import { ModuleConfig } from './models/module';
import { config as iamLoginFlowConfig } from './modules/iam-login-flow';
import { config as iamUserFlowConfig } from './modules/iam-user';
import { RoutesConfig } from './router/lib/routes-config';
import { Dictionary } from './types';

const moduleConfigs: ModuleConfig[] = [iamLoginFlowConfig, iamUserFlowConfig];

export const modules: Dictionary<ModuleConfig> = moduleConfigs.reduce(
  (acc, config) => ({
    ...acc,
    [config.moduleName]: config,
  }),
  {},
);

export function getRootSagas(): Saga[] {
  let sagas: Saga[] = [];
  for (const key in modules) {
    if (modules[key]) {
      sagas = sagas.concat(modules[key].listenerSagas);
    }
  }
  return sagas;
}

export function getReducers() {
  let reducers = {};
  for (const key in modules) {
    if (modules[key]) {
      const reducer = modules[key].redux.reducer;
      if (reducer) {
        reducers = {
          ...reducers,
          ...reducer,
        };
      }
    }
  }
  return reducers;
}

export function getRoutes(): RoutesConfig {
  let routes: RoutesConfig = {};
  for (const key in modules) {
    if (modules[key]) {
      routes = {
        ...routes,
        ...modules[key].routes,
      };
    }
  }
  return {
    ...routes,
  };
}
