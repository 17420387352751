import { ComponentType, FunctionComponent } from 'react';
import { Helmet } from 'react-helmet';
import { injectIntl, IntlShape } from 'react-intl';
import { SimpleMessage, SolarisLogo } from 'sb-ui-components';
import AppHabitat from '../../../../components/app';
import { IamLoginFlowSteps } from '../../models/iam-login-flow';
import './iam-login-flow-callback.css';

export interface IamClientPageProps {
  currentIamCallbackStep: IamLoginFlowSteps;
  loggedIn: boolean;
  currentComponent: ComponentType<any>;
}

const IamLoginFlowCallbackPage: FunctionComponent<IamClientPageProps & { intl: IntlShape }> = props => {
  const {
    intl: { formatMessage },
    currentIamCallbackStep,
    loggedIn,
  } = props;
  const title = formatMessage({ id: 'pageSectionTitle.loading' });
  const baseClass = 's-iam-callback';

  return (
    <AppHabitat>
      <Helmet title={title} />
      {loggedIn && (
        <>
          <div className={baseClass}>
            <div className={`${baseClass}__wrapper`}>
              <SolarisLogo className={`${baseClass}__logo`} />
              <h1 className={`${baseClass}__title`}>webUI-ng Login</h1>
            </div>
          </div>
          <div className={`${baseClass}__status`}>
            <SimpleMessage type="default" text={formatMessage({ id: `iam.callbackStep.${currentIamCallbackStep}` })} />
          </div>
        </>
      )}
    </AppHabitat>
  );
};

export default injectIntl(IamLoginFlowCallbackPage);
