// This file contains the sagas used for async actions in our app. It's divided into
// "effects" that the sagas call (`authorize` and `logout`) and the actual sagas themselves,
// which listen for actions.
// Sagas help us gather all our side effects (network requests in this case) in one place
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { finishProgressAction, startProgressAction } from 'src/redux/progress-bar/progress-bar-actions';
import authentication from '../models/authentication';
import {
  AUTH_LOGIN_REQUEST_ACTION,
  authRequestError,
  LoginRequestAction,
  sendingAuthRequest,
  setAuthState,
} from '../redux/auth/auth-actions';
import history from '../router/lib/history';
import { NullableString } from '../types';

export function forwardTo(location: string) {
  history.push(location);
}

export function replaceAndRemoveLocation(location: string) {
  authentication.removeLastLocation();
  window.location.assign(location);
}

export function* goToLocationOfLastSession(lastLocation: NullableString) {
  const defaultPostLoginLocation = '/';
  yield lastLocation ? replaceAndRemoveLocation(lastLocation) : forwardTo(defaultPostLoginLocation);
  // We are coming here after successful IAM login, so we need to do a full reload
  window.location.reload();
}

interface AuthorizeSaga {
  username: string;
  password: string;
}
/**
 * Effect to handle authorization
 * @param  {string} username               The username of the user
 * @param  {string} password               The password of the user
 */
export function* authorize({ username, password }: AuthorizeSaga): SagaIterator<boolean> {
  // We send an action that tells Redux we're sending a request
  yield put(sendingAuthRequest(true));

  // We then try to register or log in the user, depending on the request
  try {
    // For either log in, we call the proper function in the `auth`
    // module, which is asynchronous. Because we're using generators, we can work
    // as if it's synchronous because we pause execution until the call is done
    // with `yield`!
    const response = yield call(authentication.login, { username, password });
    return response;
  } catch (error) {
    yield put(authRequestError({ httpResponseCode: error.status }));

    return false;
  } finally {
    // When done, we tell Redux we're not in the middle of a request any more
    yield put(sendingAuthRequest(false));
  }
}

/**
 * Effect to handle logging out
 */
export function* logout(): SagaIterator<void> {
  yield call(authentication.logout);
}

/**
 * Log in saga
 */

export function* listenToLoginRequest(): SagaIterator<void> {
  yield takeEvery(AUTH_LOGIN_REQUEST_ACTION, handleLoginRequest);
}

export function* handleLoginRequest(request: LoginRequestAction): SagaIterator<void> {
  if (!request.payload) {
    return;
  }

  yield put(startProgressAction());

  const { username, password } = request.payload;

  const isAuthorized = yield call(authorize, { username, password });

  yield put(finishProgressAction());

  if (isAuthorized) {
    yield put(setAuthState(true)); // User is logged in (authorized)
    window.location.reload();
  }
}
