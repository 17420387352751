import axios, { AxiosError, AxiosResponse } from 'axios';
import { AuthenticationErrorResponse, AuthenticationSuccessPayload } from '../types';

export interface LoginParams {
  username: string;
  password: string;
}

export interface LoginResponse<T> {
  data: T;
  status: number;
}

export function loginRequest({ username, password }: LoginParams) {
  return new Promise(
    (
      resolve: (payload: LoginResponse<AuthenticationSuccessPayload>) => void,
      reject: (payload: LoginResponse<AuthenticationErrorResponse>) => void,
    ) => {
      axios
        .request({
          url: '/login',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
          method: 'post',
          data: {
            username,
            password,
            grant_type: 'password',
          },
        })
        .then((response: AxiosResponse<AuthenticationSuccessPayload>) => {
          const { data, status } = response;
          return resolve({ data, status });
        })
        .catch((error: AxiosError<AuthenticationErrorResponse>) => {
          const { data, status } = error.response!;
          return reject({ data, status });
        });
    },
  );
}
