import { FunctionComponent, ReactNode } from 'react';
import './app.css';

interface AppHabitatProps {
  children: ReactNode;
}

const AppHabitat: FunctionComponent<AppHabitatProps> = props => <div className="s-app-habitat">{props.children}</div>;

export default AppHabitat;
